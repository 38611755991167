import { createSlice } from "@reduxjs/toolkit";
import { IFiltersSlice } from "../interfaces/globalComponents/filters/redux/IFiltersSlice";
import { getInterestValue } from "../lib/filters/interests";
import { IFiltersOptions } from "../interfaces/globalComponents/filters/redux/IFiltersOptions";

const initialState = {
  blogs: {
    sports: [],
    levels: [],
    objectives: [],
    interests: [],
    queryStringFilter: "",
  },
  listings: {
    sports: [],
    levels: [],
    objectives: [],
    interests: [],
    queryStringFilter: "",
  },
  coaches: {
    sports: [],
    levels: [],
    objectives: [],
    interests: [],
    queryStringFilter: "",
  },
  workouts: {
    sports: [],
    levels: [],
    objectives: [],
    interests: [],
    queryStringFilter: "",
  },
  globalSearchTerm: "",
} as IFiltersSlice;

const addQueryStringFilter = ({
  type,
  id,
  filterFor,
}: {
  id: number | string;
  type: "sports" | "objectives" | "levels" | "interests";
  filterFor: IFiltersOptions;
}) => {
  const filterString = `${type}=${id}`;

  const queryStringArray = filterFor.queryStringFilter.split("&");
  queryStringArray.push(filterString);
  filterFor.queryStringFilter = queryStringArray.join("&");
};

const removeQueryStringFilter = ({
  type,
  id,
  filterFor,
}: {
  id: number | string;
  type: "sports" | "objectives" | "levels" | "interests";
  filterFor: IFiltersOptions;
}) => {
  const filterString = `${type}=${id}`;
  const queryStringArray = filterFor.queryStringFilter
    .split("&")
    .filter((item) => item !== filterString);
  filterFor.queryStringFilter = queryStringArray.join("&");
};

const addSearchTermLocalStorage = (searchTerm: string) => {
  if (!window.localStorage.getItem("searchHistory")) {
    window.localStorage.setItem("searchHistory", JSON.stringify([]));
  }

  let searchHistoryFromLocalStorage = JSON.parse(
    window.localStorage.getItem("searchHistory")
  ) as string[];

  if (searchHistoryFromLocalStorage.length >= 3) {
    searchHistoryFromLocalStorage = [
      searchHistoryFromLocalStorage[1],
      searchHistoryFromLocalStorage[2],
      searchTerm,
    ];
  }

  if (searchHistoryFromLocalStorage.length < 3) {
    searchHistoryFromLocalStorage.push(searchTerm);
  }

  window.localStorage.setItem(
    "searchHistory",
    JSON.stringify(searchHistoryFromLocalStorage)
  );
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState: initialState,
  reducers: {
    setIdFilters: (
      state,
      action: {
        payload: {
          id: number;
          type: "sports" | "objectives";
          filterFor: "blogs" | "listings" | "workouts" | "coaches";
        };
      }
    ) => {
      if (
        state[action.payload.filterFor][action.payload.type].includes(
          action.payload.id
        )
      ) {
        state[action.payload.filterFor][action.payload.type] = state[
          action.payload.filterFor
        ][action.payload.type].filter((item) => item !== action.payload.id);
        removeQueryStringFilter({
          filterFor: state[action.payload.filterFor],
          type: action.payload.type,
          id: action.payload.id,
        });
      } else {
        state[action.payload.filterFor][action.payload.type].push(
          action.payload.id
        );

        addQueryStringFilter({
          filterFor: state[action.payload.filterFor],
          type: action.payload.type,
          id: action.payload.id,
        });
      }
    },
    setTitleFilters: (
      state,
      action: {
        payload: {
          title: string;
          type: "levels" | "interests";
          filterFor: "blogs" | "listings" | "workouts" | "coaches";
        };
      }
    ) => {
      let filterValue = action.payload.title;
      if (action.payload.type === "levels") {
        filterValue = filterValue.toLowerCase();
      } else if (action.payload.type === "interests") {
        filterValue = getInterestValue(filterValue);
      }

      if (
        state[action.payload.filterFor][action.payload.type].includes(
          filterValue
        )
      ) {
        state[action.payload.filterFor][action.payload.type] = state[
          action.payload.filterFor
        ][action.payload.type].filter((item) => item !== filterValue);
        removeQueryStringFilter({
          filterFor: state[action.payload.filterFor],
          type: action.payload.type,
          id: filterValue,
        });
      } else {
        state[action.payload.filterFor][action.payload.type].push(filterValue);
        addQueryStringFilter({
          filterFor: state[action.payload.filterFor],
          type: action.payload.type,
          id: filterValue,
        });
      }
    },
    removeAllFilters: (
      state,
      action: {
        payload: {
          filterFor: "blogs" | "listings" | "workouts" | "coaches";
          type: "objectives" | "sports" | "levels" | "interests";
        };
      }
    ) => {
      if (
        action.payload.type === "interests" ||
        action.payload.type === "levels"
      ) {
        state[action.payload.filterFor][action.payload.type].forEach((item) => {
          removeQueryStringFilter({
            filterFor: state[action.payload.filterFor],
            type: action.payload.type,
            id: item.toLowerCase(),
          });
        });
        state[action.payload.filterFor][action.payload.type] = [];
      }

      if (
        action.payload.type === "objectives" ||
        action.payload.type === "sports"
      ) {
        state[action.payload.filterFor][action.payload.type].forEach((item) => {
          removeQueryStringFilter({
            filterFor: state[action.payload.filterFor],
            type: action.payload.type,
            id: item,
          });
        });
        state[action.payload.filterFor][action.payload.type] = [];
      }
    },
    addSearchTerm: (
      state,
      action: { payload: { searchTerm: string; addInHistory?: boolean } }
    ) => {
      const oldGlobalSearchTerm = `searchQuery=${state.globalSearchTerm}`;
      state.globalSearchTerm = action.payload.searchTerm;
      const searchTermQuery = `searchQuery=${action.payload.searchTerm}`;

      action.payload.addInHistory &&
        addSearchTermLocalStorage(action.payload.searchTerm);

      Object.keys(state).forEach(
        (
          key:
            | "blogs"
            | "listings"
            | "coaches"
            | "workouts"
            | "globalSearchTerm"
        ) => {
          if (key != "globalSearchTerm") {
            const queryStringArray = state[key].queryStringFilter
              .split("&")
              .filter((item) => item !== oldGlobalSearchTerm);
            queryStringArray.push(searchTermQuery);
            state[key].queryStringFilter = queryStringArray.join("&");
          }
        }
      );
    },
    removeSearchTerm: (state) => {
      const searchTermQuery = `searchQuery=${state.globalSearchTerm}`;
      state.globalSearchTerm = "";

      Object.keys(state).forEach(
        (
          key:
            | "blogs"
            | "listings"
            | "coaches"
            | "workouts"
            | "globalSearchTerm"
        ) => {
          if (key != "globalSearchTerm") {
            const queryStringArray = state[key].queryStringFilter
              .split("&")
              .filter((item) => item !== searchTermQuery);
            state[key].queryStringFilter = queryStringArray.join("&");
          }
        }
      );
    },
  },
});

export const {
  setIdFilters,
  setTitleFilters,
  removeAllFilters,
  addSearchTerm,
  removeSearchTerm,
} = filtersSlice.actions;

export default filtersSlice.reducer;
