import { createSlice } from "@reduxjs/toolkit";
import { IGlobalSlice } from "../interfaces/globalComponents/redux/IGlobalSlice";

const initialState: IGlobalSlice = {
  snackbar: {
    showSnackbar: false,
  },
};

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    setShowSnackbar: (state, action: { payload: boolean }) => {
      state.snackbar.showSnackbar = action.payload;
    },
    setSnackbarText: (state, action: { payload: string }) => {
      state.snackbar.snackbarText = action.payload;
      state.snackbar.showSnackbar = true;
    },
  },
});

export const { setShowSnackbar, setSnackbarText } = globalSlice.actions;
export default globalSlice.reducer;
