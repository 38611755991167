import { createSlice } from "@reduxjs/toolkit";
import { ITeamSlice } from "../../interfaces/team/redux/ITeamSlice";
import { Team } from "../../interfaces/team/Team";
import { Listing } from "../../interfaces/training-plan/Listing";

const initialState: ITeamSlice = {
  teamData: null,
  loading: true,
  isOpenShareModal: false,
  teamListings: null,
  tabBarValue: 0,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setCurrentTeam: (
      state,
      action: { payload: { team: Team; listings: Listing[] } }
    ) => {
      state.teamData = action.payload.team;
      state.teamListings = action.payload.listings;
      state.loading = false;
    },
    setIsOpenShareModal: (state, action: { payload: boolean }) => {
      state.isOpenShareModal = action.payload;
    },
    setTabBarValue: (state, action: { payload: number }) => {
      state.tabBarValue = action.payload;
    },
  },
});

export const { setCurrentTeam, setIsOpenShareModal, setTabBarValue } =
  teamSlice.actions;
export default teamSlice.reducer;
