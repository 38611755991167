import { createSlice } from "@reduxjs/toolkit";
import { BodyHistoryItem } from "../interfaces/onboarding/injuries/BodyHistoryItem";

export interface payloadHistoryInjuryInterface {
  showInjuryHistoryModal: boolean;
}

export interface payloadAddInjuryInterface {
  isLoadingAddInjury?: boolean;
  showSuccessMessage?: boolean;
  isLoadingInjuries?: boolean;
  showInjuryOptions?: boolean;
  showBodyAreaOptions?: boolean;
  selectedBodyArea?: boolean;
  showInjuryModal?: boolean;
  modalTitle?: string;
  isEditing?: boolean;
  injuryToUpdate?: BodyHistoryItem;
}

const initialState: {
  injuryModal: payloadAddInjuryInterface;
  injuryHistoryModal: payloadHistoryInjuryInterface;
} = {
  injuryModal: {
    isLoadingAddInjury: false,
    showSuccessMessage: false,
    isLoadingInjuries: false,
    showInjuryOptions: false,
    showBodyAreaOptions: false,
    selectedBodyArea: false,
    showInjuryModal: false,
    modalTitle: "Log an Injury",
    injuryToUpdate: null,
    isEditing: false,
  },
  injuryHistoryModal: {
    showInjuryHistoryModal: false,
  },
};

export const injurySlice = createSlice({
  name: "injury",
  initialState,
  reducers: {
    setStateInjuryModal: (
      state,
      action: { payload: payloadAddInjuryInterface }
    ) => {
      state.injuryModal = { ...state.injuryModal, ...action.payload };
    },
    setStateHistoryInjuryModal: (
      state,
      action: { payload: payloadHistoryInjuryInterface }
    ) => {
      state.injuryHistoryModal = {
        ...state.injuryHistoryModal,
        ...action.payload,
      };
    },
  },
});

export const { setStateInjuryModal, setStateHistoryInjuryModal } =
  injurySlice.actions;
export default injurySlice.reducer;
