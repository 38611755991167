import { createSlice } from "@reduxjs/toolkit";
import { IConfirmPopupSlice } from "../interfaces/training-plan/confirmPopup/IConfirmPopupSlice";

const initialState = {
  value: { isOpened: false, data: { text: "" }, currentAction: 0 },
} as IConfirmPopupSlice;

export const confirmPopupSlice = createSlice({
  name: "confirmPopUp",
  initialState,
  reducers: {
    setConfirmModalRedux: (state, action: { payload: { text: string } }) => {
      state.value.data = action.payload;
    },
    setOpenedConfirmModalRedux: (state) => {
      state.value.isOpened = !state.value.isOpened;
    },
    setCurrentActionRedux: (state, action: { payload: number }) => {
      state.value.currentAction = action.payload;
    },
  },
});

export const {
  setConfirmModalRedux,
  setOpenedConfirmModalRedux,
  setCurrentActionRedux,
} = confirmPopupSlice.actions;

export default confirmPopupSlice.reducer;
