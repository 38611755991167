import { IComponentColors } from "../../interfaces/material/componentColors";

export const lightComponentColors: IComponentColors = {
  primary: {
    primary: "#146683",
    onPrimary: "#FFFFFF",
    primaryContainer: "#BEE9FF",
    onPrimaryContainer: "#001F2A",
  },
  secondary: {
    secondary: "#4D616C",
    onSecondary: "#FFFFFF",
    secondaryContainer: "#D0E6F2",
    onSecondaryContainer: "#081E27",
  },
  tertiary: {
    tertiary: "#5E5B7D",
    onTertiary: "#FFFFFF",
    tertiaryContainer: "#E4DFFF",
    onTertiaryContainer: "#1A1836",
  },
  error: {
    error: "#BA1A1A",
    onError: "#FFFFFF",
    errorContainer: "#FFDAD6",
    onErrorContainer: "#410002",
  },
  surface: {
    surface: "#F6FAFE",
    onSurface: "#171C1F",
    surfaceDim: "#D6DBDE",
    surfaceBright: "#F6FAFE",
    surfContainer: "#EAEEF2",
    surfContainerHigh: "#E4E9EC",
    surfContainerHighest: "#DFE3E7",
    surfcontainerLow: "#F0F4F8",
    surfContainerLowest: "#FFFFFF",
    onSurfaceVariant: "#40484C",
    onSurfaceDetachable: "#000",
    surfaceFull: "#000",
    surfaceContainerHightVariation: "#EAE7EC",
  },
  outline: {
    outline: "#70787D",
    outlineVariant: "#C0C8CD",
  },
  background: {
    onBackground: "#171C1F",
  },
};
