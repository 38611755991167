import { IComponentColors } from "../../interfaces/material/componentColors";

export const darkComponentColors: IComponentColors = {
  primary: {
    primary: "#8CCFF0",
    onPrimary: "#003546",
    primaryContainer: "#004D65",
    onPrimaryContainer: "#BEE9FF",
  },
  secondary: {
    secondary: "#B4CAD6",
    onSecondary: "#1F333D",
    secondaryContainer: "#354A54",
    onSecondaryContainer: "#D0E6F2",
  },
  tertiary: {
    tertiary: "#C7C2EA",
    onTertiary: "#2F2D4C",
    tertiaryContainer: "#464364",
    onTertiaryContainer: "#E4DFFF",
  },
  error: {
    error: "#FFB4AB",
    onError: "#690005",
    errorContainer: "#93000A",
    onErrorContainer: "#FFDAD6",
  },
  surface: {
    surface: "#0F1417",
    onSurface: "#DFE3E7",
    onSurfaceVariant: "#C0C8CD",
    surfaceBright: "#353A3D",
    surfContainerLowest: "#0A0F12",
    surfcontainerLow: "#171C1F",
    surfContainer: "#1B2023",
    surfContainerHigh: "#262B2E",
    surfContainerHighest: "#303538",
    surfaceDim: "#0F1417",
    onSurfaceDetachable: "#FFF",
    surfaceFull: "#FFF",
    surfaceContainerHightVariation: "#25252D",
  },
  outline: {
    outline: "#8A9297",
    outlineVariant: "#40484C",
  },
  background: {
    onBackground: "#DFE3E7",
  },
};
