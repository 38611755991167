import { createSlice } from "@reduxjs/toolkit";
import { IObjectiveRedux } from "../interfaces/globalComponents/filters/redux/IObjectiveSlice";
import { IObjective } from "../interfaces/globalComponents/filters/filterItems/IObjective";
const initialState = { value: [] } as IObjectiveRedux;

export const objectivesSlice = createSlice({
  name: "objectives",
  initialState,
  reducers: {
    setObjectivesRedux: (
      state,
      action: { payload: IObjective[]; type: string }
    ) => {
      state.value = [...action.payload];
    },
  },
});

export const { setObjectivesRedux } = objectivesSlice.actions;

export default objectivesSlice.reducer;
