import { createSlice } from "@reduxjs/toolkit";
import { IBlogsSlice } from "../interfaces/blog/IBlogsSlice";

const initialState = { value: 0 } as IBlogsSlice;

export const profileSlice = createSlice({
  name: "blog slice",
  initialState,
  reducers: {
    setFilterBlogRedux: (state, action: { payload: number; type: string }) => {
      state.value = action.payload;
    },
  },
});

export const { setFilterBlogRedux } = profileSlice.actions;
export default profileSlice.reducer;
