export const getInterestValue = (value: string) => {
  let returnValue = "";

  switch (value) {
    case "Strength & Conditioning":
      returnValue = "strength";
      break;
    case "Performance Nutrition":
      returnValue = "nutrition";
      break;

    case "Athletic Therapy":
      returnValue = "therapy";
      break;

    case "Sports Psychology":
      returnValue = "psychology";
      break;
  }

  return returnValue;
};
