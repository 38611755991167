import { createSlice } from "@reduxjs/toolkit";
import { IForgotPasswordPageSlice } from "../../interfaces/auth/redux/IForgotPasswordPageSlice";

const initialState: IForgotPasswordPageSlice = {
  isLoadingPage: false,
  isLoadingForm: false,
  showSuccessModal: false,
};

const forgotPasswordPageSlice = createSlice({
  name: "forgotPassword_page",
  initialState,
  reducers: {
    setIsLoadingPage: (state, action: { payload: boolean }) => {
      state.isLoadingPage = action.payload;
    },
    setIsLoadingForm: (state, action: { payload: boolean }) => {
      state.isLoadingForm = action.payload;
    },
    setShowSuccessModal: (state, action: { payload: boolean }) => {
      state.showSuccessModal = action.payload;
    },
  },
});

export default forgotPasswordPageSlice.reducer;

export const { setShowSuccessModal, setIsLoadingPage, setIsLoadingForm } =
  forgotPasswordPageSlice.actions;
