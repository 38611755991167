import { createSlice } from "@reduxjs/toolkit";
import {
  ChartDataType,
  IProfileSlice,
  ProfileCurrentSection,
} from "../interfaces/profile/IProfileSlice";
import { IUserData } from "../interfaces/user/IUserData";
import { IExerciseHistory } from "../interfaces/exercise/IExerciseHistory";
import { Exercise } from "../interfaces/exercise/Exercise";

const initialState = {
  value: {
    user: {},
    profile: { showDropdownDots: false },
    currentSection: ProfileCurrentSection.PROFILE,
    isLoadingProfileForm: true,
    isProfileFormInitialized: false,
    exerciseHistory: {
      chartData: null,
      chartDataType: ChartDataType.WEIGHT,
      isLoadingExerciseHistory: true,
      currentExerciseHistory: [],
      currentYearHistory: new Date().getFullYear(),
      currentMonthHistory: new Date().getMonth() + 1,
      currentExercise: null,
    },
  },
} as IProfileSlice;

export const profileSlice = createSlice({
  name: "profile_section_state",
  initialState,
  reducers: {
    setUserRedux: (state, action: { payload: IUserData }) => {
      state.value.user = action.payload;
    },
    setUserImage: (state, action: { payload: string }) => {
      state.value.user.avatarUrl = action.payload;
    },
    setImageLoading: (state) => {
      state.value.isLoadingImage = !state.value.isLoadingImage;
    },
    setShowDropdownDots: (state, action: { payload: boolean }) => {
      state.value.profile.showDropdownDots = action.payload;
    },
    setExerciseHistory: (state, action: { payload: IExerciseHistory[] }) => {
      state.value.exerciseHistory.currentExerciseHistory = action.payload;
    },
    setIsLoadingExerciseHistory: (state, action: { payload: boolean }) => {
      state.value.exerciseHistory.isLoadingExerciseHistory = action.payload;
    },
    setIsLoadingProfileForm: (state, action: { payload: boolean }) => {
      state.value.isLoadingProfileForm = action.payload;
    },
    setIsProfileformInitialized: (state, action: { payload: boolean }) => {
      state.value.isProfileFormInitialized = action.payload;
    },
    setChartDataType: (state, action: { payload: ChartDataType }) => {
      state.value.exerciseHistory.chartDataType = action.payload;
    },
    setCurrentDate: (state, action: { payload: string }) => {
      const currentDate = new Date(action.payload);

      state.value.exerciseHistory.currentMonthHistory =
        new Date(currentDate).getMonth() + 1;
      state.value.exerciseHistory.currentYearHistory = new Date(
        currentDate
      ).getFullYear();
    },
    setExercise: (state, action: { payload: Exercise }) => {
      state.value.exerciseHistory.currentExercise = action.payload;
    },
    setCurrentProfileSection: (
      state,
      action: { payload: ProfileCurrentSection }
    ) => {
      state.value.currentSection = action.payload;
    },
  },
});
export const {
  setExerciseHistory,
  setUserRedux,
  setUserImage,
  setImageLoading,
  setShowDropdownDots,
  setIsLoadingExerciseHistory,
  setChartDataType,
  setCurrentDate,
  setExercise,
  setCurrentProfileSection,
  setIsLoadingProfileForm,
  setIsProfileformInitialized,
} = profileSlice.actions;
export default profileSlice.reducer;
