import { createSlice } from "@reduxjs/toolkit";

interface ISearchSlice {
  value: boolean;
  searchValue: string;
  showMobileModal: boolean;
}

const initialState = {
  value: false,
  searchValue: "",
  showMobileModal: false,
} as ISearchSlice;

export const searchSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {
    setSearchRedux: (state) => {
      state.value = !state.value;
    },
    setSearchValue: (state, action: { payload: string }) => {
      state.searchValue = action.payload;
    },
    setShowMobileModal: (state, action: { payload: boolean }) => {
      state.showMobileModal = action.payload;
    },
  },
});

export const { setSearchRedux, setSearchValue, setShowMobileModal } =
  searchSlice.actions;

export default searchSlice.reducer;
