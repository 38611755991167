import { createSlice } from "@reduxjs/toolkit";

interface RegisterSlice {
  isLoadingPage: boolean;
  isLoadingForm: boolean;
  showRegisterModal: boolean;
  errorMessages: ErrorMessages;
}

interface ErrorMessages {
  emailErrorMessage?: string;
  passwordErrorMessage?: string;
  nameErrorMessage?: string;
  formErrorMessage?: string;
}

const initialState: RegisterSlice = {
  isLoadingPage: false,
  isLoadingForm: false,
  showRegisterModal: false,
  errorMessages: {
    emailErrorMessage: "",
    passwordErrorMessage: "",
    nameErrorMessage: "",
    formErrorMessage: "",
  },
};

export const registerPageSlice = createSlice({
  name: "register_page",
  initialState,
  reducers: {
    setIsLoadingPage: (state, action: { payload: boolean }) => {
      state.isLoadingPage = action.payload;
    },
    setIsLoadingForm: (state, action: { payload: boolean }) => {
      state.isLoadingForm = action.payload;
    },
    setRegisterModal: (state) => {
      state.showRegisterModal = !state.showRegisterModal;
    },
    setErrorMessages: (state, action: { payload: ErrorMessages }) => {
      state.errorMessages = { ...state.errorMessages, ...action.payload };
    },
  },
});

export const {
  setIsLoadingPage,
  setIsLoadingForm,
  setErrorMessages,
  setRegisterModal,
} = registerPageSlice.actions;

export default registerPageSlice.reducer;
