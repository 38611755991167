import { createSlice } from "@reduxjs/toolkit";
import { ISubscription } from "../interfaces/user/ISubscription";

interface InitialState {
  value: {
    loggedUser: boolean;
    rendered: boolean;
    subscription: ISubscription;
    avatarUrl: string;
    loading: boolean;
  };
}

const initialState = {
  value: {
    loggedUser: null,
    rendered: false,
    subscription: null,
    avatarUrl: null,
  },
} as InitialState;

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setLoggedInUser: (state, action: { payload: boolean }) => {
      state.value.loggedUser = action.payload;
      state.value.rendered = true;
    },

    setSubscription: (state, action: { payload: ISubscription }) => {
      state.value.subscription = action.payload;
    },
    setAvatarUrl: (state, action: { payload: string }) => {
      state.value.avatarUrl = action.payload;
    },
  },
});

export const { setLoggedInUser, setSubscription, setAvatarUrl } =
  userSlice.actions;

export default userSlice.reducer;
