import { createSlice } from "@reduxjs/toolkit";

interface LoginPageSlice {
  isLoadingPage: boolean;
  isLoadingForm: boolean;
  showLoginModal: boolean;
  errorMessages: ErrorMessages;
}

interface ErrorMessages {
  emailErrorMessage: string;
  passwordErrorMessage: string;
  formErrorMessage: string;
}

const initialState: LoginPageSlice = {
  isLoadingPage: false,
  isLoadingForm: false,
  showLoginModal: false,
  errorMessages: {
    emailErrorMessage: "",
    passwordErrorMessage: "",
    formErrorMessage: "",
  },
};

export const loginPageSlice = createSlice({
  name: "login_page",
  initialState,
  reducers: {
    setIsLoadingPage: (state, action: { payload: boolean }) => {
      state.isLoadingPage = action.payload;
    },
    setIsLoadingForm: (state, action: { payload: boolean }) => {
      state.isLoadingForm = action.payload;
    },
    setLoginModal: (state) => {
      state.showLoginModal = !state.showLoginModal;
    },
    setErrorMessages: (state, action: { payload: Partial<ErrorMessages> }) => {
      state.errorMessages = { ...state.errorMessages, ...action.payload };
    },
  },
});

export const {
  setIsLoadingPage,
  setIsLoadingForm,
  setLoginModal,
  setErrorMessages,
} = loginPageSlice.actions;

export default loginPageSlice.reducer;
