import { createSlice } from "@reduxjs/toolkit";
import { IThemeSlice } from "../interfaces/globalComponents/filters/redux/IThemeSlice";
import { darkComponentColors } from "../styles/material/darkTheme";
import { lightComponentColors } from "../styles/material/lightTheme";

const initialState = {
  componentColors: darkComponentColors,
  mode: "dark",
} as IThemeSlice;

export const themeSlice = createSlice({
  name: "materialTheme",
  initialState,
  reducers: {
    changeMode: (state) => {
      if (state.mode == "dark") {
        state.mode = "light";
        state.componentColors = lightComponentColors;

        return;
      }

      state.mode = "dark";
      state.componentColors = darkComponentColors;
    },
  },
});

export const { changeMode } = themeSlice.actions;

export default themeSlice.reducer;
