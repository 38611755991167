import { createSlice } from "@reduxjs/toolkit";
import { Listing } from "../../interfaces/training-plan/Listing";
import { IUserData } from "../../interfaces/user/IUserData";

export interface LibraryState {
  currentLibrarySection: CurrentLibrarySection;
  trainingSection: TrainingSection;
  coachesSection: CoachesSection;
}

export interface TrainingSection {
  isLoading?: boolean;
  userTrainingPlans?: Listing[];
  workouts?: Listing[];
  bookmarkedTrainingAndWorkouts?: Listing[];
  recommendedTrainingPlans?: Listing[];
}

export interface CoachesSection {
  isLoading?: boolean;
  userCoaches?: IUserData[];
  followingCoaches?: IUserData[];
}

export enum CurrentLibrarySection {
  LISTINGS = "listings",
  COACHES = "coaches",
}

const libraryInitialState: LibraryState = {
  currentLibrarySection: CurrentLibrarySection.LISTINGS,
  trainingSection: {
    isLoading: true,
    userTrainingPlans: [],
    workouts: [],
    bookmarkedTrainingAndWorkouts: [],
    recommendedTrainingPlans: [],
  },
  coachesSection: {
    isLoading: true,
    userCoaches: [],
    followingCoaches: [],
  },
};

const librarySlice = createSlice({
  name: "library",
  initialState: libraryInitialState,
  reducers: {
    changeLibraryTab: (state, action: { payload: CurrentLibrarySection }) => {
      state.currentLibrarySection = action.payload;
    },
    updateTrainingSection: (state, action: { payload: TrainingSection }) => {
      state.trainingSection = { ...state.trainingSection, ...action.payload };
    },
    updateCoachesSection: (state, action: { payload: CoachesSection }) => {
      state.coachesSection = { ...state.coachesSection, ...action.payload };
    },
  },
});

export default librarySlice.reducer;
export const { updateTrainingSection, changeLibraryTab, updateCoachesSection } =
  librarySlice.actions;
