import { createSlice } from "@reduxjs/toolkit";
import { ICategorySlice } from "../interfaces/globalComponents/filters/redux/ICategorySlice";

const initialState = { value: 0 } as ICategorySlice;

export const categoryFilter = createSlice({
  name: "category slice",
  initialState,
  reducers: {
    setCategoryRedux: (state, action: { payload: number; type: string }) => {
      state.value = action.payload;
    },
  },
});

export const { setCategoryRedux } = categoryFilter.actions;
export default categoryFilter.reducer;
