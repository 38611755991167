import { createSlice } from "@reduxjs/toolkit";
import { IBadgeSlice } from "../interfaces/globalComponents/verificationBadge/IBadgeSlice";

const initialState = { value: false } as IBadgeSlice;

export const badgeModalSlice = createSlice({
  name: "badgeSlice",
  initialState,
  reducers: {
    setIsOpenedBadgeModal: (state) => {
      state.value = !state.value;
    },
  },
});

export const { setIsOpenedBadgeModal } = badgeModalSlice.actions;
export default badgeModalSlice.reducer;
