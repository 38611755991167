import { createSlice } from "@reduxjs/toolkit";
import { ISportsSlice } from "../interfaces/globalComponents/filters/redux/ISportsSlice";
import { ISport } from "../interfaces/globalComponents/filters/filterItems/ISport";

const initialState = { value: [] } as ISportsSlice;

export const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {
    setSportsRedux: (state, action: { payload: ISport[] }) => {
      state.value = [...action.payload];
    },
  },
});

export const { setSportsRedux } = sportsSlice.actions;

export default sportsSlice.reducer;
