import { createSlice } from "@reduxjs/toolkit";

interface loginServiceSlice {
  errorMessage?: string;
}

const initialState: loginServiceSlice = {
  errorMessage: null,
};

const loginServiceSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setServiceErrorMessage: (state, action: { payload: string }) => {
      state.errorMessage = action.payload;
    },
  },
});

export default loginServiceSlice.reducer;
export const { setServiceErrorMessage } = loginServiceSlice.actions;
