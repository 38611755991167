import { createSlice } from "@reduxjs/toolkit";
import { IMenuSlice } from "../interfaces/globalComponents/menu/redux/IMenuSlice";

const initialState: IMenuSlice = {
  value: {
    showProfile: false,
    isRendered: false,
    showMobileMenu: false,
    showDrawer: false,
    selectedMenuItem: null,
  },
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setShowProfile: (state, action: { payload: boolean }) => {
      state.value.showProfile = action.payload;
    },
    setIsRenderedMenu: (state) => {
      state.value.isRendered = true;
    },
    changeShowMobileMenu: (state) => {
      state.value.showMobileMenu = !state.value.showMobileMenu;
    },
    changeShowDrawer: (state) => {
      state.value.showDrawer = !state.value.showDrawer;
    },
    setCurrentMenuItem: (state, action: { payload: number }) => {
      state.value.selectedMenuItem = action.payload;
    },
  },
});

export const {
  setShowProfile,
  setIsRenderedMenu,
  changeShowMobileMenu,
  changeShowDrawer,
  setCurrentMenuItem,
} = menuSlice.actions;

export default menuSlice.reducer;
