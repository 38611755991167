import { createSlice } from "@reduxjs/toolkit";
import { ILoginSlice } from "../interfaces/auth/ILoginSlice";

const initialState = { value: false } as ILoginSlice;

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginModalRedux: (state) => {
      state.value = !state.value;
    },
  },
});

export const { setLoginModalRedux } = loginSlice.actions;

export default loginSlice.reducer;
