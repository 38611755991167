import { createSlice } from "@reduxjs/toolkit";
import { IDrawerFilters } from "../../interfaces/globalComponents/layout/material/NavigationDrawer/IDrawerFilters";

export interface IExploreSlice {
  value: {
    exploreTabOption: number;
    filtersList: IDrawerFilters;
    openModal: {
      menu: boolean;
      drawer: boolean;
      bottomsheet: boolean;
    };
    pages: {
      trainingPlan: {
        page: number;
        isLoadingLoadMoreBtn: boolean;
        hasMore?: boolean;
        isLoadingItems: boolean;
      };
      coaches: {
        page: number;
        isLoadingLoadMoreBtn: boolean;
        hasMore?: boolean;
        isLoadingItems: boolean;
      };
      workouts: {
        page: number;
        isLoadingLoadMoreBtn: boolean;
        hasMore?: boolean;
        isLoadingItems: boolean;
      };
    };
  };
}

export const exploreSlice = createSlice({
  name: "title",
  initialState: {
    value: {
      exploreTabOption: 0,
      anchorFilterDialog: null,
      openModal: {
        menu: false,
        drawer: false,
        bottomsheet: false,
      },
      filtersList: {
        levels: [],
        sports: [],
        objectives: [],
        interests: [],
      },
      pages: {
        trainingPlan: {
          page: 0,
          isLoadingLoadMoreBtn: false,
          isLoadingItems: true,
          hasMore: true,
        },
        coaches: {
          page: 0,
          isLoadingLoadMoreBtn: false,
          isLoadingItems: true,
          hasMore: true,
        },
        workouts: {
          page: 0,
          isLoadingLoadMoreBtn: false,
          isLoadingItems: true,
          hasMore: true,
        },
      },
    },
  } as IExploreSlice,
  reducers: {
    setTabSection: (state, action: { payload: number }) => {
      state.value.exploreTabOption = action.payload;
    },
    setTrainingPlanPage: (state, action: { payload: number }) => {
      state.value.pages.trainingPlan.page = action.payload;
    },
    increaseTrainingPlanPage: (state) => {
      state.value.pages.trainingPlan.page =
        state.value.pages.trainingPlan.page + 1;
    },
    setIsLoadingLoadMoreBtn: (state, action: { payload: boolean }) => {
      state.value.pages.trainingPlan.isLoadingLoadMoreBtn = action.payload;
    },
    setIsLoadingTrainingPlans: (state, action: { payload: boolean }) => {
      state.value.pages.trainingPlan.isLoadingItems = action.payload;
    },
    setIsLoadingWorkouts: (state, action: { payload: boolean }) => {
      state.value.pages.workouts.isLoadingItems = action.payload;
    },
    setIsLoadingCoaches: (state, action: { payload: boolean }) => {
      state.value.pages.coaches.isLoadingItems = action.payload;
    },
    setIsLoadingLoadMoreBtnCoaches: (state, action: { payload: boolean }) => {
      state.value.pages.coaches.isLoadingLoadMoreBtn = action.payload;
    },
    increaseCoachesPage: (state) => {
      state.value.pages.coaches.page = ++state.value.pages.coaches.page;
    },
    setCoachesPage: (state, action: { payload: number }) => {
      state.value.pages.coaches.page = action.payload;
    },
    setIsLoadingLoadMoreBtnWorkouts: (state, action: { payload: boolean }) => {
      state.value.pages.workouts.isLoadingLoadMoreBtn = action.payload;
    },
    increaseWorkoutsPage: (state) => {
      state.value.pages.workouts.page = ++state.value.pages.workouts.page;
    },
    setWorkoutsPage: (state, action: { payload: number }) => {
      state.value.pages.workouts.page = action.payload;
    },
    setFiltersList: (state, action: { payload: IDrawerFilters }) => {
      state.value.filtersList = action.payload;
    },
    setOpenModal: (
      state,
      action: {
        payload: {
          menu?: boolean;
          drawer?: boolean;
          bottomsheet?: boolean;
        };
      }
    ) => {
      state.value.openModal = { ...state.value.openModal, ...action.payload };
    },
    setHasMoreListings: (state, action: { payload: boolean }) => {
      state.value.pages.trainingPlan.hasMore = action.payload;
    },
    setHasMoreCoaches: (state, action: { payload: boolean }) => {
      state.value.pages.coaches.hasMore = action.payload;
    },
    setHasMoreWorkouts: (state, action: { payload: boolean }) => {
      state.value.pages.workouts.hasMore = action.payload;
    },
  },
});

export const {
  setTabSection,
  increaseTrainingPlanPage,
  setTrainingPlanPage,
  setIsLoadingLoadMoreBtn,
  setIsLoadingTrainingPlans,
  setIsLoadingWorkouts,
  setIsLoadingCoaches,
  increaseCoachesPage,
  setIsLoadingLoadMoreBtnCoaches,
  setCoachesPage,
  setIsLoadingLoadMoreBtnWorkouts,
  increaseWorkoutsPage,
  setWorkoutsPage,
  setFiltersList,
  setOpenModal,
  setHasMoreListings,
  setHasMoreWorkouts,
  setHasMoreCoaches,
} = exploreSlice.actions;
export default exploreSlice.reducer;
