import { createSlice } from "@reduxjs/toolkit";
import { ISpecialistSlice } from "../../interfaces/training-plan/redux/ISpecialistSlice";
import { IUserData } from "../../interfaces/user/IUserData";
import { ListingsFromApi } from "../../interfaces/training-plan/ListingsFromApi";

const initialState: ISpecialistSlice = {
  coachData: null,
  similarListings: null,
  loading: true,
  isFollowing: undefined,
  isOpenShareModal: false,
  isOpen1to1Modal: false,
  tabBarValue: 0,
};

export const specialistSlice = createSlice({
  name: "specialistSlice",
  initialState,
  reducers: {
    setSpecialistPageInitialData: (
      state,
      action: {
        payload: {
          coachData: IUserData;
          similarListings: ListingsFromApi;
        };
      }
    ) => {
      state.coachData = action.payload.coachData;
      state.similarListings = action.payload.similarListings;
      state.loading = false;
    },
    setCoachData: (state, action: { payload: IUserData }) => {
      state.coachData = action.payload;
    },
    setSimilarListings: (state, action: { payload: ListingsFromApi }) => {
      state.similarListings = action.payload;
    },
    setIsFollowing: (state, action: { payload: boolean }) => {
      state.isFollowing = action.payload;
    },
    setIsOpenShareModal: (state, action: { payload: boolean }) => {
      state.isOpenShareModal = action.payload;
    },
    setIsOpen1to1Modal: (state, action: { payload: boolean }) => {
      state.isOpen1to1Modal = action.payload;
    },
    setTabBarValue: (state, action: { payload: number }) => {
      state.tabBarValue = action.payload;
    },
  },
});

export const {
  setCoachData,
  setSpecialistPageInitialData,
  setSimilarListings,
  setIsFollowing,
  setIsOpenShareModal,
  setIsOpen1to1Modal,
  setTabBarValue,
} = specialistSlice.actions;

export default specialistSlice.reducer;
