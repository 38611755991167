import { createSlice } from "@reduxjs/toolkit";
import { IBodyAreasSlice } from "../interfaces/globalComponents/filters/redux/IBodyAreasSlice";
import { MuscleArea } from "../interfaces/onboarding/injuries/BodyInjury";

const initialState = { value: [] } as IBodyAreasSlice;

export const bodyAreasSlice = createSlice({
  name: "category slice",
  initialState,
  reducers: {
    setBodyAreasRedux: (
      state,
      action: { payload: MuscleArea[]; type: string }
    ) => {
      state.value = action.payload;
    },
  },
});

export const { setBodyAreasRedux } = bodyAreasSlice.actions;
export default bodyAreasSlice.reducer;
