import { createSlice } from "@reduxjs/toolkit";
import { IStripePriceSlice } from "../interfaces/payment/redux/IStripePriceSlice";

const initialState: { value: IStripePriceSlice } | null = { value: null };

export const stripePricesSlice = createSlice({
  name: "stripePrices",
  initialState: initialState,
  reducers: {
    setStripePrices: (state, action: { payload: IStripePriceSlice }) => {
      state.value = action.payload;
    },
  },
});

export const { setStripePrices } = stripePricesSlice.actions;
export default stripePricesSlice.reducer;
