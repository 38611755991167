import { createSlice } from "@reduxjs/toolkit";
import {
  IPaymentSlice,
  ICurrentTextsForButtons,
  CurrentSubscription,
} from "../../interfaces/payment/redux/IPaymentSlice";

const initialState: IPaymentSlice = {
  isLoading: false,
  isLoadingInitialData: true,
  currentSubscription: null,
  currentTextsForButtons: {
    free: "Current Plan",
    bronze: "Upgrade",
    silver: "Upgrade",
    gold: "Upgrade",
  },
  gotUpdatedUser: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setIsLoadingPaymentPage: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setIsLoadingInitialData: (state, action: { payload: boolean }) => {
      state.isLoadingInitialData = action.payload;
    },
    setCurrentSubscription: (
      state,
      action: { payload: CurrentSubscription }
    ) => {
      state.currentSubscription = action.payload;
    },
    setCurrentTextsForButtons: (
      state,
      action: { payload: ICurrentTextsForButtons }
    ) => {
      state.currentTextsForButtons = {
        ...state.currentTextsForButtons,
        ...action.payload,
      };
    },
    setGotUpdatedUser: (state, action: { payload: boolean }) => {
      state.gotUpdatedUser = action.payload;
    },
  },
});

export const {
  setIsLoadingPaymentPage,
  setIsLoadingInitialData,
  setCurrentTextsForButtons,
  setCurrentSubscription,
  setGotUpdatedUser,
} = paymentSlice.actions;
export default paymentSlice.reducer;
