import { createSlice } from "@reduxjs/toolkit";
import { IRegisterSlice } from "../interfaces/auth/IRegisterSlice";

const initialState = { value: false } as IRegisterSlice;

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterModalRedux: (state) => {
      state.value = !state.value;
    },
  },
});

export const { setRegisterModalRedux } = registerSlice.actions;

export default registerSlice.reducer;
