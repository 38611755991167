import { createSlice } from "@reduxjs/toolkit";
import { IForgotPasswordSlice } from "../interfaces/auth/IForgotPasswordSlice";

const initialState = { value: false } as IForgotPasswordSlice;

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    setForgotPasswordModalRedux: (state) => {
      state.value = !state.value;
    },
  },
});

export const { setForgotPasswordModalRedux } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
