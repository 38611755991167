import { Exercise } from "../exercise/Exercise";
import { IExerciseHistory } from "../exercise/IExerciseHistory";
import { IUserData } from "../user/IUserData";

export interface IProfileSlice {
  value: {
    user: IUserData;
    isLoadingImage: boolean;
    profile: { showDropdownDots: boolean };
    currentSection: ProfileCurrentSection;
    isLoadingProfileForm: boolean;
    isProfileFormInitialized: boolean;
    exerciseHistory: {
      chartDataType: ChartDataType;
      isLoadingExerciseHistory: boolean;
      currentExerciseHistory: IExerciseHistory[];
      currentYearHistory: number;
      currentMonthHistory: number;
      currentExercise: Exercise;
      chartData: Map<string, string>;
    };
  };
}

export enum ChartDataType {
  WEIGHT = "weight",
  REPS = "reps",
  RM = "rm",
}

export enum ProfileCurrentSection {
  PROFILE = 0,
  HISTORY = 1,
  SPORTS = 2,
  OBJECTIVES = 3,
  EQUIPMENTS = 4,
  INJURIES = 5,
}
